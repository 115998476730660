.bl-search-input-container {
  &.MuiContainer-root {
    margin: 0;
    padding: 1em;

    .MuiFormHelperText-root {
      display: none;
    }

    .MuiInput-root {
      background-color: var(--bluon--default--white);

      .MuiSvgIcon-root {
        margin-right: 0.5rem;
      }
    }
  }

  .bl-search-input {
    ::placeholder {
      font-style: normal;
    }
    &.blue-border {
      border-width: 2px;
      border-color: var(--bluon--default--blue-1);
    }

    &.show-border {
      border: 1px solid var(--bluon--light--gray-17);
    }
  }
}
