.thank-you-dialog-container {
  .dialog-title {
    height: 99px;
    background: var(--bluon--default--navy);
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiTypography-h5 {
      font-size: 1.75rem;
      color: var(--bluon--default--white);
      font-weight: 400;
      padding-right: 0.45em;
    }
    img {
      padding-top: 3px;
    }
  }

  .dialog-subtitle {
    .MuiTypography-h5 {
      font-size: 1.375rem;
      font-weight: bold;

      b {
        color: var(--bluon--brand--blue);
      }
    }
  }

  .list-container {
    justify-content: center;
    margin: 1em 0;

    .MuiListItem-root {
      padding-bottom: 0;
      padding-top: 0;

      .MuiListItemIcon-root {
        min-width: 35px;
      }
    }
  }

  .dialog-actions {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
  }

  .MuiPaper-root {
    max-width: 500px;
  }
}
