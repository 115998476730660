.forgot-password-form-container {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .forgot-password-form-title {
    margin-bottom: 2em;
  }

  .forgot-password-button-container {
    margin-top: 2em;
    margin-bottom: 1.875em;
  }
}

.bl-forgot-password-container {
  max-width: 38rem;
  width: 100%;
  padding: 1.5rem;

  .form {
    display: flex;
    flex-direction: column;
    margin: 2.5rem 0;
    gap: 1rem;
  }

  .footer-buttons {
    display: flex;

    gap: 1rem;
  }
}
