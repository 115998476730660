.b4b-login-container {
  max-width: 37.5rem;
  width: 100%;
  padding: 1.5rem;

  .form {
    gap: 2rem;
    margin: 2.5rem 0;
  }

  .divider {
    width: 100%;
    padding: 1rem 0;
  }

  .footer-buttons {
    display: flex;
    gap: 0.5rem;
  }
}
