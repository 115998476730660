.bl-set-new-password-container {
  .bl-set-new-password-title-container {
    margin-bottom: 2em;
  }

  .bl-set-new-password-button-container {
    margin-bottom: 1.875em;
  }

  .go-back-button {
    .MuiSvgIcon-root {
      font-size: 15px;
      padding-bottom: 5px;
    }
  }
}

.bl-set-password-container {
  max-width: 37.5rem;
  width: 100%;
  padding: 1.5rem;

  .bl-take-rate-container {
    font-weight: 500;
    font-size: 0.875rem;
    color: var(--bluon--darker--gray);
    margin-bottom: 2rem;
  }
  .form {
    display: flex;
    flex-direction: column;
    margin: 2.5rem 0;
    gap: 0.5rem;

    .inputs {
      gap: 2.5rem;
    }
  }
}
