.commercial-model-part-container {
  .part-number-container {
    .part-number {
      &.full {
        width: 100%;
      }

      &.with-btn {
        width: calc(100% - 20.625rem);
        margin-right: 1rem;
      }

      .MuiFormHelperText-contained {
        font-weight: 400;
      }
    }

    .Mui-disabled {
      background-color: var(--bluon--ds--lightgray-button-bg);
      opacity: 0.7;
    }
  }
}
