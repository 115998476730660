.MUIAccordion {
  &.MuiAccordion-root {
    border: 1px solid var(--bluon--ds--lightgray-4);
    border-radius: 0.25rem;
    &:before {
      height: 0px;
    }

    &.Mui-expanded {
      margin: 0;
    }
    .MuiAccordionSummary-root {
      min-height: 0;
      height: 2.625rem;
    }
  }
}
