.bl-contractor-login-form {
  display: flex;
  justify-content: center;
}

.bl-contractor-login-container {
  .bl-contractor-login-header-container {
    justify-content: center;
    margin-bottom: 2em;
  }

  .bl-contractor-login-inputs-container {
    justify-content: center;
  }

  .bl-contractor-login-phone-number-container {
    padding-left: 1em;

    .MuiBox-root {
      display: flex;

      .bl-number-format-error-icon {
        right: 5px;
        top: 42px;
        position: absolute;
      }
    }
  }

  .bl-contractor-login-send-sms-button-container {
    justify-content: center;
    margin-top: 2em;
  }

  .bl-contractor-login-text-container {
    justify-content: center;
    margin-top: 0.8em;
  }

  .bl-contractor-login-link-container {
    justify-content: center;
    margin-top: 1.875em;
  }
}
