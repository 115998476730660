.app-main-navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  border-bottom: 3px solid var(--vanta-10, rgba(0, 0, 0, 0.1));
  width: 100%;
  background-color: var(--bluon--ds--white);
  height: 4rem;

  .bluon-logo {
    width: 5rem;
    height: 2rem;
    cursor: pointer;
  }

  .dropdown-image {
    object-fit: contain;
    height: 1.5rem;
    max-width: 13.125rem;
  }

  .nav-tabs-container {
    .MuiTabs-root {
      overflow: visible;

      .MuiTabs-scroller {
        overflow: visible;
      }

      .MuiTabs-indicator {
        height: 0.188rem;
        bottom: -0.188rem;
      }
    }
  }
}
