.bl-upload-invoice-area {
  padding: 1.5rem 1rem;
  width: 100%;
  height: 21.75rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;

  .bl-upload-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .disabled-upload-text {
      u {
        opacity: 0.75;
        color: var(--bluon--default--black);
      }
      opacity: 0.38;
      color: var(--bluon--default--black);
    }
  }

  &.pending {
    background: var(--bluon--ds--selected);
    border: 1px dashed var(--bluon--ds--lightblack);
  }

  &.success {
    background: var(--bluon--ds--selected);
    border: 1px dashed var(--bluon--ds--brand);
  }

  &.error {
    background: var(--bluon--ds--red--background--light);
    border: 1px dashed var(--bluon--ds--red);
  }

  &.loading {
    background: var(--bluon--ds--brand--light--background);
    border: 1px dashed var(--bluon--ds--brand);
  }

  .MuiTypography-subtitle1 {
    font-size: 1rem;
    font-weight: 400;
    color: var(--bluon--ds--lightblack-primary);
    u {
      color: var(--bluon--ds--button--highlight--text);
      cursor: pointer;
    }
  }

  .MuiTypography-caption {
    font-weight: 400;
    font-size: 0.875rem;
    letter-spacing: 0.011rem;
    color: var(--bluon--ds--lightblack);
  }

  .primary-button {
    &.MuiIconButton-root {
      color: var(--bluon--ds--brand);

      &:hover {
        color: var(--bluon--ds--brand);
        background-color: var(--bluon--ds--brand--hover);
      }
    }
  }

  .disabled-button {
    &.MuiIconButton-root {
      background: var(--bluon--ds--light-blue-shades, rgba(25, 118, 210, 0.12));
      color: var(--bluon--ds--lightgray-primary);
    }
  }

  .document-tab {
    position: absolute;
    border-radius: 0.25rem;
    width: 93%;
    margin: 1rem;
    bottom: 0;
    background-color: var(--bluon--ds--white);

    .MuiListItemText-root {
      cursor: pointer;
    }
    .MuiListItemAvatar-root {
      cursor: pointer;
    }

    .upload-error {
      &.MuiListItem-root {
        color: var(--bluon--ds--red);

        .MuiTypography-body2 {
          color: var(--bluon--ds--red);
        }

        .upload-icon {
          .MuiSvgIcon-root {
            color: var(--bluon--ds--red);
          }
        }

        .MuiAvatar-root {
          background-color: var(--bluon--ds--white);
        }
      }
    }

    .upload-default {
      .upload-icon {
        .MuiSvgIcon-root {
          color: var(--bluon--ds--brand);
        }
      }
      .MuiAvatar-root {
        background-color: var(--bluon--ds--brand--hover);
      }
    }

    .upload-disabled {
      .upload-icon {
        .MuiSvgIcon-root {
          color: var(--bluon--ds--lightgray-primary);
        }
      }

      .MuiTypography-subtitle1 {
        color: var(--bluon--ds--lightblack-primary);
      }

      .MuiAvatar-root {
        color: var(--bluon--ds--lightgray-primary);
        background: var(--bluon--ds--lightgray-background);
      }
    }
  }
}
