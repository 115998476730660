.bl-help-nav {
  position: static;
  height: 4rem;
  justify-content: center;

  .bl-help-nav-title {
    color: var(--bluon--brand--blue);
    font-weight: 500;
    margin-left: 2rem;
  }
}

.bl-help-page-container {
  box-sizing: border-box;
  height: 85%;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem 4rem;
  background: var(--bluon--light--gray-3);

  .bl-help-iframe-container {
    flex: 1 1 0;
    height: 100%;

    .bl-help-iframe {
      flex: 1 1 0;
      height: 100%;
      width: 101%;
      border: none;
    }
  }
}
