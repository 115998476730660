.snackbar-content {
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0px 1px 10px 0px var(--bluon--default--black);
  word-break: break-word;

  .close-x-button {
    bottom: 10px;
    left: 6px;
    .MuiSvgIcon-root {
      width: 0.7em;
      height: 0.7em;
    }
  }

  .snackbar-message-container {
    .MuiTypography-body2 {
      font-size: 0.688rem;
    }
  }
}
