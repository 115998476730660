.bl-notification-container {
    .bl-notification-form-wrapper {
      background-color: var(--bluon--default--white);
      padding: 1rem;
      border-radius: 4px;
    }

    .MuiTableHead-root {
      th {
        border-bottom: 0;
      }

      .bl-notification-table-cell-large {
        width: 70%;
      }

      .bl-notification-table-cell-small {
        vertical-align: top;
      }

      .MuiTypography-body1 {
        font-weight: bold;
      }

      .MuiTypography-body2 {
        color: var(--bluon--darker--gray);
      }
    }
    
    .MuiTableBody-root {
      tr:nth-child(odd) {
        background: var(--bluon--light--gray-4);
      }

      td {
        border-bottom: 0;
      }
    }
}
  