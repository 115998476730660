.terms-of-service {
  position: absolute;
  right: 38px;
  bottom: 31px;
  .MuiTypography-caption {
    color: var(--bluon--light--navy);
    font-size: 0.875rem;
  }

  a {
    color: var(--bluon--brand--blue);
    cursor: pointer;
  }
}
