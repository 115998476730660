.warning-center-icon {
  color: var(--bluon--default--yellow);
  display: block;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
}

.bl-change-login-credential-dialog {
  .MuiSvgIcon-root {
    height: 45px;
    width: 45px;
  }

  .MuiDialogContent-root {
    margin: 0;
    padding: 0px 0.75em;
  }

  .MuiDialogActions-root {
    align-self: center;
    margin: 0;
    padding: 0;
  }

  .modal-content-container {
    border-radius: 4px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.03);
    padding: 2em 0.25em;
    width: 383px;

    .title {
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      margin-bottom: 0.688em;
      text-align: center;
    }

    .subtitle-one {
      margin-bottom: 1em;
    }

    .subtitle {
      margin-bottom: 1.5em;
    }

    .subtitle-one,
    .subtitle {
      color: var(--bluon--default--black);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
    }

    .cancel-btn,
    .accept-btn {
      border-radius: 4px;
      box-shadow: none;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
    }

    .accept-btn {
      background-color: var(--bluon--default--yellow);
      color: var(--bluon--default--black);
    }
  }
}
