.box-container {
  display: flex;

  .drag-drop-styles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-width: 99px;
    height: 55px;
    background: var(--bluon--light--blue-3);

    border-radius: 3px;
    text-align: center;
    margin-right: 1em;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    padding: 1rem;
  }

  .has-error {
    background: rgba(255, 135, 101, 0.1);
    border: 1px solid var(--bluon--default--peach);
  }

  .button-area {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .drag-drop-skeleton {
    margin-right: 1em;
    width: 100%;
    height: 55px;
    background: var(--bluon--light--blue-3);
    border-radius: 3px;
    transform: none;
  }
}
