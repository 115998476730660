.bl-unconfirmed-customer-card {
  border-radius: 0.875rem;
  background: var(--bluon--ds--selected);
  padding: 0.5rem 1rem;
  width: 17.75rem;

  .MuiTypography-subtitle2 {
    font-weight: 400;
    word-break: break-word;
  }

  .highlight-name {
    color: var(--bluon--ds--brand);
  }
}
