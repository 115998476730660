.mm-container {
  background-color: var(--bluon--ds--white);

  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: calc(-4rem + 100vh);
  position: relative;
  overflow-y: auto;

  .mm-iframe {
    height: calc(-4rem + 100vh);
    min-width: 100%;
  }
}
