.children-container {
  &.MuiBox-root {
    border: 1px solid var(--bluon--ds--light-gray-4);
    border-radius: 0.25rem;
    padding: 1rem;
    margin-bottom: 0.5rem;
  }
}

.special-input-selector-form-box {
  margin-bottom: 1.5rem;
}
