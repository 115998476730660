.forgot-password-modal {
  .MuiPaper-root {
    height: 21.9rem;
    width: 21.9rem;
  }

  .forgot-password-warning-icon-container {
    justify-content: center;
    margin-top: 1.5rem;
    .forgot-password-warning-icon {
      height: 32px;
      width: 32px;
    }
  }

  .forgot-password-content-container {
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .forgot-password-content-text-container {
      padding-top: 0;

      .forgot-password-content-text {
        color: var(--bluon--default--black);
      }
    }
  }

  .forgot-password-buttons-container {
    justify-content: center;

    .forgot-password-reset-button {
      background-color: var(--bluon--default--yellow);
      color: var(--bluon--default--black);
      width: 77.32px;
    }
  }
}
