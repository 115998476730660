ul.clip-list-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  gap: 0.5rem;
}

.cant-delete-button {
  &.MuiButtonBase-root {
    cursor: inherit;
    &:hover {
      background-color: var(--bluon--ds--blue-50);
    }
  }
}
