.custom-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;

  &.Mui-selected {
    color: var(--bluon--ds--blue-500);
  }

  .menu-item-icon {
    margin-left: 1rem;
  }
}
