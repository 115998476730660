.bl-login-container {
  height: 100vh;

  .container-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
  }
  .form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .login-side-container {
    justify-content: center;
    flex-direction: column;
    background-color: var(--bluon--lighter--navy);

    .grow-map {
      padding-bottom: 0.5rem;
    }

    .bl-logo-title-container {
      .bluon-logo {
        width: 100%;
        padding-top: 0.5rem;
        padding-bottom: 1rem;
      }
    }
  }
}
