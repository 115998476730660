:root {
  --bluon--default--black: #000000;
  --bluon--default--white: #ffffff;
  --bluon--dark--gray: #868686;
  --bluon--darker--gray: #979797;
  --bluon--default--gray: #c4c4c4;
  --bluon--light--gray: #d9d9d9;
  --bluon--light--gray-1: #d8d8d8;
  --bluon--light--gray-2: #dfe1e5;
  --bluon--light--gray-3: #eaedf3;
  --bluon--light--gray-4: #f5f6fa;
  --bluon--light--gray-5: #d0d0d0;
  --bluon--light--gray-6: #efefef;
  --bluon--light--gray-7: #e8eaee;
  --bluon--light--gray-8: #e5e5e5;
  --bluon--light--gray-9: #97a5b4;
  --bluon--light--gray-10: #f4f5f7;
  --bluon--light--gray-11: #ededed;
  --bluon--light--gray-12: #e0e1e6;
  --bluon--light--gray-13: #9ba4b0;
  --bluon--light--gray-14: #fafbfc;
  --bluon--light--gray-15: #929292;
  --bluon--light--gray-16: #ffffff59;
  --bluon--light--gray-17: #d6d9dc;
  --bluon--light--gray-18: #898a8d;
  --bluon--light--gray-19: #f2f2f2;
  --bluon--light--gray-20: #dfdfdf;
  --bluon--light--gray-21: #f7f7f7;
  --bluon--light--gray-22: #e0e0e0;
  --bluon--light--gray-order-detail: #f5f5f5;
  --bluon--light--gray-24: #393939;
  --bluon--default--blue--gray: #757e86;
  --bluon--default--blue--gray-1: #8d97a5;
  --bluon--default--blue--gray-2: #9b9eac;
  --bluon--default--blue--gray-3: #a1a9b2;
  --bluon--default--blue--gray-4: #b4b4be;
  --bluon--default--blue--gray-5: #c7c7cc;
  --bluon--default--blue--gray-6: #ebecf0;
  --bluon--default--blue--gray-7: #94a8c5;
  --bluon--default--blue--gray-8: #e6f0fa;
  --bluon--default--blue--gray-9: #f8f8f8;
  --bluon--dark--navy: #253342;
  --bluon--darker--navy: #1f2a31;
  --bluon--default--navy: #304050;
  --bluon--light--navy: #374850;
  --bluon--light--navy-1: #323e4a;
  --bluon--light--navy-2: #484f58;
  --bluon--light--navy-3: #5b616e;
  --bluon--light--navy-4: #42526e;
  --bluon--lighter--navy: #3d5066;
  --bluon--brand--blue: #0567d0;
  --bluon--brand--blue-2: #0667d0;
  --bluon--brand--light--blue: #4285ad;
  --bluon--default--blue: #015bbc;
  --bluon--default--blue-1: #017cff;
  --bluon--default--blue-2: #0647a6;
  --bluon--default--blue-3: #0567d1;
  --bluon--default--blue-4: #90caf9;
  --bluon--default--blue--transparent: #015bbc1a;
  --bluon--dark--blue: #004596;
  --bluon--dark--blue-1: #007bff;
  --bluon--dark--blue-2: #3d5066;
  --bluon--light--blue: #64b5f6;
  --bluon--light--blue-1: #a7caef;
  --bluon--light--blue-2: #edf6ff;
  --bluon--light--blue-3: #dfebff;
  --bluon--light--blue-4: #f6fbff;
  --bluon--default--green: #00b246;
  --bluon--dark--green: #16a64e;
  --bluon--dark--green-1: #007d31;
  --bluon--darker--green: #068b3a;
  --bluon--light--green: #a6e4be;
  --bluon--light--green-1: #e3f7eb;
  --bluon--light--green-2: #e9fcf0;
  --bluon--lighter--green: #c1ecd2;
  --bluon--default--yellow: #ffd772;
  --bluon--light--yellow: #fffaee;
  --bluon--light--yellow-2: #cdab34;
  --bluon--light--yellow-3: #fcfae9;
  --bluon--default--red: #de360c;
  --bluon--dark--red: #dd3217;
  --bluon--dark--red-1: #c16659;
  --bluon--dark--red-2: #9e2304;
  --bluon--light--red: #fbcbbd;
  --bluon--light--red-1: #e1e1e1;
  --bluon--light--red-2: #c16659;
  --bluon--light--red-3: #efdddd;
  --bluon--light--red-4: #d31a00;
  --bluon--light--red-5: #c62828;
  --bluon--light--red-6: #fdf1f1;
  --bluon--default--peach: #ff8765;
  --bluon--light--peach: #fff3f0;
  --bluon--light--orange: #f69b11;
  --bluon--light--orange-1: #ffb44c;
  --bluon--dark--orange: #dc8201;
  --bluon--brown--light: #fffce5;
  --bluon--brown--dark: #775d00;
  --bluon--light-blue--background: #f1f8ff;
}
