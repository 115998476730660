.lb-subtitle {
  font-size: 0.875rem;
  color: var(--bluon--darker--gray);
  font-weight: 400;
}

.disabled-label {
  opacity: 0.32;
}

.text-input-skeleton {
  &.MuiSkeleton-root {
    height: 2.75rem;
    border-radius: 3px;
    transform: none;
    background-color: var(--bluon--light--gray-6);
  }
}

.inline-label {
  display: flex;
  align-items: center;
}

.warning-icon {
  padding-left: 0.2em;
}
