.bl-brands-actions-buttons {
  padding: 1rem;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--bluon--ds--lightgray-3);

  .actions-container {
    display: flex;
    gap: 0.438rem;
  }

  .max-amount-error-color {
    color: var(--bluon--light--red-4);
  }
}
