.bl-unconfirmed-customers-actions {
  padding: 0 1.5rem;

  .radio-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .modal-form {
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
    gap: 1.5rem;

    .account-number-field,
    .custom-tag-field {
      .MuiFormHelperText-root {
        font-weight: 400;
      }
    }
  }

  .MuiFormLabel-root {
    font-weight: 400;
    color: var(--bluon--ds--lightblack);
  }
}
