.msg-container-full-img {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 3.125em 6.25em;
  position: relative;
  width: 100%;

  .alignment {
    position: relative;
    max-height: 100vh;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    .full-img-file {
      max-height: 100vh;
      max-width: 100%;
      object-fit: contain;
    }

    .close-container {
      align-items: center;
      background-color: var(--bluon--default--black);
      border-radius: 50px;
      cursor: pointer;
      display: flex;
      height: 28px;
      justify-content: center;
      position: absolute;
      right: 20px;
      top: 16px;
      width: 28px;

      .close-icon {
        color: var(--bluon--default--white);
      }
    }
  }
}
