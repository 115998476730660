.bl-brands-request-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  height: 100%;

  color: var(--bluon--ds--darkblue);
}
