td .bl-edit-modal-button,
td .bl-edit-modal-button:hover {
  border-color: var(--bluon--default--black);
  color: var(--bluon--default--black);
}

.bl-edit-modal-dialog-container {
  .MuiPaper-root {
    width: 400px;
  }

  .modal-form {
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
    gap: 1.5rem;
  }

  .bl-edit-modal-avatar-container {
    display: flex;
    justify-content: center;
    margin: 0 1em 1em 1em;
  }

  .bl-edit-modal-dialog-text {
    color: var(--bluon--default--black);
    text-align: center;
    font-weight: 400;
  }

  .bl-edit-modal-form-control-container {
    display: flex;
    margin-bottom: 0.5rem;

    .bl-edit-modal-radio-group-container {
      display: flex;
      justify-content: center;

      .bl-edit-modal-form-control-label {
        margin: 0;
      }
    }

    .bl-edit-modal-helper-text {
      display: flex;
      justify-content: center;
      margin-bottom: 0.8em;
      font-size: 1rem;
      color: var(--bluon--default--peach);
    }
  }

  .bl-edit-modal-input {
    margin-bottom: 1.5em;
  }

  .MuiFormLabel-root {
    font-weight: 400;
    color: var(--bluon--ds--lightblack);
  }

  .bl-edit-modal-buttons-container {
    justify-content: center;
    column-gap: 0.8rem;
    margin-top: 1rem;
  }

  .bl-edit-modal-cancel-button-container {
    text-align: right;
  }
}
