.blue-cell {
    background-color: var(--bluon--default--blue--gray-8);
    color: var(--bluon--ds--brand);
  }
  
  .red-cell {
    background-color: var(--bluon--light--red-3);
    color: var(--bluon--ds--red);
  }
  
  .gray-cell {
    background-color: var(--bluon--ds--lightgray-3);
    color: var(--bluon--ds--lightgray-1);
  }
  
  .green-cell {
    background-color: var(--bluon--light--green-2);
    color: var(--bluon--default--green);
  }
  
  .yellow-cell{
    background-color: var(--bluon--light--yellow-3);
    color: var(--bluon--light--yellow-2);
  }
  