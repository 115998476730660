.claiming-modal-dialog {
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  z-index: 1399 !important;
  -webkit-backdrop-filter: blur(10px);
  padding: 0 !important;

  .MuiPaper-root {
    max-width: none;
    max-height: none;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    padding: 2rem 0px;

    .claiming-modal-container-content {
      height: 100%;
      width: 100%;

      .cm-container-close {
        width: 100%;
        height: 3.188rem;
        padding-left: 2rem;

        .close-container,
        .close-btn {
          height: 2.5rem;
          width: 2.5rem;
          min-width: 0;
          border-radius: 6.25rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--bluon--default--blue-3);

          .close-icon {
            width: 1rem;
            height: 1rem;
            max-width: none;
            color: var(--bluon--default--white);
          }
        }
      }
    }
  }
}
