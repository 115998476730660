.bl-customer-table-container {
  th,
  td {
    border: none;
  }

  tbody tr:nth-child(odd) {
    background: var(--bluon--light--gray-4);
  }

  .bl-customer-table-column-label {
    color: var(--bluon--ds--black);
    opacity: 0.8;
    font-weight: 500;
  }

  .bl-customer-table-cell {
    display: flex;

    .bl-customer-table-customer-tier {
      display: inline-block;
      padding: 0.25rem 1rem;
      background: var(--bluon--light--blue-3);
      border-radius: 2px;
      color: #0747a6;
    }

    .bl-customer-table-primary-action-modal {
      margin-right: 0.75em;
    }
  }

  .bl-customer-table-waypoint {
    height: 1px;
  }
}

.bl-customer-table-paper-container {
  margin: 1.5em 0;

  .content {
    h6 {
      min-height: 0;
    }
  }
}
