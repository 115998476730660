@import '../../assets/scss/utils.scss';
.loading-button {
  &.MuiButton-root {
    @include responsiveProperty(padding-right, 1.5rem, 1.5rem, 1.5rem, 1.5rem);
    @include responsiveProperty(padding-left, 1.5rem, 1.5rem, 1.5rem, 1.5rem);
  }
  position: relative;

  .loading {
    position: absolute;
  }

  .container-children {
    &.loading {
      position: relative;
      visibility: hidden;
    }
  }
}
