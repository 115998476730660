.bl-mark-as-complete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .update-button {
    &.MuiButton-root {
      background-color: var(--bluon--ds--lightblue);
      padding: 1rem 2.875rem;
    }
  }

  .picked-up-button {
    &.MuiButton-root {
      background-color: var(--bluon--default--green);
      padding: 1rem 2.875rem;
    }
  }
}
