.totals-form-container {
  .input-container {
    margin-bottom: 2rem;

    & textarea {
      height: 5.625rem;
    }
  }

  .MuiFormHelperText-root {
    color: var(--bluon--ds--lightblack);
    font-weight: 400;
    font-size: 0.75rem;
    margin-right: 0;
  }

  .MuiInputLabel-root {
    color: var(--bluon--ds--lightblack);
    font-weight: 400;
    line-height: 1.5rem;
  }

  .red-color {
    color: var(--bluon--ds--red);
  }
}
