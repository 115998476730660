.MuiGrid-container.b4b-auth-header-container {
  display: flex;
  flex-direction: column;

  .MuiTypography-body1 {
    color: var(--bluon--ds--gray);
  }

  .MuiTypography-caption {
    color: var(--bluon-ds--tomato-500);
  }
}
