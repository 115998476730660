.bl-customer-dashboard-container {
  margin-bottom: 1.5em;
  padding: 1rem;

  .bl-customer-dashboard-header-container {
    display: flex;
    align-items: center;

    .bl-customer-dashboard-counter-container {
      color: var(--bluon--default--blue);
      background-color: var(--bluon--default--blue--transparent);
      border-radius: 5px;
      margin-left: 0.6em;

      .bl-customer-dashboard-counter {
        padding: 0.2rem 0.4rem;
      }
    }
  }

  .bl-customer-dashboard-tabs.bl-customer-dashboard-tabs-spacing {
    padding-left: 0;
    margin: 0 1em;
    border-bottom: 1px solid var(--bluon--light--gray-17);
  }
}
