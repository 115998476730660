.bl-dashboard-container {
  height: 100%;
  position: relative;

  &.MuiContainer-root {
    padding: 0.7rem 1rem 1.4rem 1rem;
    margin-top: 0;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.03);
    padding-block-end: 4.25rem;
  }

  .title {
    font-size: 1.5rem;
    line-height: 123.5%;

    @media only screen and (min-width: 600px) {
      font-size: 2.125rem;
    }
  }

  .MuiButtonBase-root.text-black {
    color: var(--bluon--ds--lightblack);
  }
}
