.counter-staff-toggle-notification-container {
  display: flex;
  align-items: center;

  .counter-staff-toggle-notification-label {
    margin-right: 0.625em;
    font-size: 0.875rem;
    line-height: 1.75em;
    font-weight: 400;
    color: var(--bluon--default--black);
  }

  .counter-staff-toggle-notification-info-icon {
    color: var(--bluon--lighter--navy);
    cursor: pointer;
  }
}
