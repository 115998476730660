.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--bluon--default--navy);
  text-align: center;

  svg {
    font-size: 4.5rem;
  }

  h5 {
    font-weight: 700;
    font-size: 1.75rem;
    margin-top: 1em;
  }

  h6 {
    font-weight: 400;
    font-size: 1.25rem;
    margin-top: 1em;
    max-width: 550px;
    min-height: 200px;
  }
}
