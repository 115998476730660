.reason-wrapper {
  .MuiFormControl-root {
    label {
      font-weight: 400;
    }
  }
  .red {
    label {
      color: var(--bluon--ds--lightred);
    }
    .MuiInputBase-root {
      fieldset {
        border-color: var(--bluon--ds--lightred);
      }
    }
  }
  .MuiFormHelperText-root {
    font-weight: 400;
  }
}

.add-new-counter-button {
  &.MuiMenuItem-root {
    width: 100%;
    height: 100%;
    display: flex;
    color: var(--bluon--ds--button--highlight--text);
    gap: 0.5rem;
    line-height: 1.5rem;
  }
}

.MuiList-root.MuiMenu-list {
  padding-top: 0;
  padding-bottom: 0;
}

.staff-wrapper {
  label {
    &.MuiFormLabel-root {
      color: var(--bluon--ds--lightblack);
      font-weight: 400;
    }
  }

  .MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-focused {
    fieldset {
      &.MuiOutlinedInput-notchedOutline {
        border-color: var(--bluon--ds--select--border);
      }
    }
  }
}
