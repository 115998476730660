.bl-brands-skeleton-list {
  .list-header {
    &.MuiTypography-subtitle2 {
      color: var(--bluon--ds--brand);
      padding: 0.75rem 1rem;
    }
  }

  .row-skeleton {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 2.625rem;
    padding: 0 1rem;
  }
}
