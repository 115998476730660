.bl-reset-password-container {
  max-width: 37.5rem;
  width: 100%;
  padding: 1.5rem;

  .form {
    display: flex;
    flex-direction: column;
    margin: 2.5rem 0;
    gap: 0.5rem;

    .inputs {
      gap: 2.5rem;
    }
  }
}
