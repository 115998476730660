.terms-of-service-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .terms-title {
    &.MuiTypography-h6 {
      font-size: 1.375rem;
      font-weight: bold;
    }
  }

  .go-back-button {
    margin-top: 2.5rem;
  }

  .terms-container {
    text-align: justify;
    flex-grow: 1;
    overflow: auto;
    max-height: 75vh;
    /* for Firefox */
    min-height: 0;
    padding-right: 1.5em;
    margin-top: 0.5em;

    em {
      font-style: normal;
    }

    h3 {
      text-align: center;
      margin-top: 3em;
    }

    h4 {
      text-align: center;
    }

    h5 {
      font-style: italic;
      text-align: center;
      font-weight: normal;
      margin-bottom: 4em;
    }

    h6 {
      margin-left: 2em;
      font-weight: normal;
      font-size: 0.8rem;
    }

    .intersection-observer-dummy {
      height: 1px;
    }
  }
}
