.custom-tab {
  &.MuiTab-root {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: none;
    min-height: 3.5rem;
    font-family: 'Roboto';
    padding: 0rem 1rem;
    height: 4rem;
    transition: padding-bottom 0.2s ease-out;

    &.Mui-selected {
      color: var(--bluon--ds--blue-500);
      background-color: var(--bluon--ds--blue-50);

      > .MuiTab-iconWrapper {
        > g > path {
          fill: var(--bluon--ds--blue-500);
        }
      }

      &:hover {
        background-color: var(--bluon--ds--blue-50);
      }
    }

    &:hover {
      background-color: var(--bluon--ds--gray-50);
      color: var(--bluon--ds--blue-300);
      padding: 0rem 1rem 0.25rem 1rem;

      > .MuiTab-iconWrapper {
        > g > path {
          fill: var(--bluon--ds--blue-300);
        }
      }
    }
  }
}
