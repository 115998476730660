.list-container {
  border: 1px solid var(--bluon--ds--border--lightgray);

  height: 72vh;

  &.empty {
    height: 14.125rem;
    justify-content: space-around;
  }

  .ReactVirtualized__Grid.ReactVirtualized__List {
    max-height: 60.5vh;
  }

  .list-title {
    font-size: 1.125rem;
    line-height: 2rem;

    @media only screen and (min-width: 600px) {
      font-size: 1.5rem;
    }
  }

  .bottom-message {
    font-size: 1.125rem;
    line-height: 2rem;
    margin-top: 0;
  }

  .empty-message {
    font-size: 1.5rem;
    line-height: 2.625rem;
    letter-spacing: 0.016rem;

    @media only screen and (min-width: 600px) {
      font-size: 2.125rem;
    }
  }
}
