.bl-brands-modal-empty-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 0.5rem;

  color: var(--bluon--ds--darkblue);

  .MuiTypography-body1 {
    width: 80%;
    word-break: break-all;
    text-align: center;
  }
}
