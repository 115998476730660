.search-container {
  padding: 16px;
}

.MuiSelect-select {
  padding: 0;
}

.custom-dropdown {
  background-color: var(--bluon--light--gray-6);
  max-height: 2.75rem;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}

.dropdown-input-skeleton {
  &.MuiSkeleton-root {
    height: 2.75rem;
    border-radius: 3px;
    transform: none;
    background-color: var(--bluon--light--gray-6);
  }
}

.dropdown-input-menu-items {
  &.MuiMenuItem-root {
    img {
      height: 1rem;
      width: 0.875rem;
      margin-left: 0.5em;

      &:first-child {
        margin-right: 0.5em;
        margin-left: 0;
      }
    }
  }
}

.dropdown-error-end-adornment {
  &.MuiInputAdornment-root {
    padding-right: 0.313rem;
  }
}
.dropdown-input-menu-sublabel {
  font-size: 0.875rem;
  margin-left: 0.25rem;
  color: var(--bluon--darker--gray);
}
