.previous-button-container {
  width: 100%;

  .previous-button {
    width: 96%;
    margin: 1em 2%;
    cursor: pointer;
    border: 1px solid var(--bluon--lighter--navy);
    padding: 0.3em;
    color: var(--bluon--lighter--navy);

    .previous-button-loader {
      height: 1.3rem;
      width: 1.3rem;
      color: var(--bluon--lighter--navy);
    }
  }
}
