#root {
  width: 100vw;
  height: 100vh;
  background-color: var(--bluon--light--gray-4);
  scrollbar-color: var(--bluon--ds--scrollbar) var(--bluon--ds--trackBar);
  a {
    text-decoration: none;
  }
}
::-webkit-scrollbar {
  width: 0.875rem;
}

::-webkit-scrollbar-track {
  background: var(--bluon--ds-white);
  border-radius: 0.625rem;
  border-left: 1px solid var(--bluon--ds--gray-400);
}

::-webkit-scrollbar-thumb {
  background: var(--bluon--ds--scrollbar);
  border-radius: 0.625rem;
  border: 0.25rem solid var(--bluon--ds-white);
}
