.bl-unconfirmed-customer-banner {
  background: var(--bluon--ds--lightgray-7);

  .MuiButtonBase-root {
    padding: 0 0.625rem;
  }

  .MuiTypography-subtitle1 {
    font-weight: 700;
    color: var(--bluon--ds--lightgray-1);
  }
}
