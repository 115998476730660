.images-list-container {
  .image {
    height: 2rem;
    width: 2rem;
    img{
      object-fit: contain;
    }
  }

  .remaining-message {
    font-size: 0.813rem;
    line-height: 1.125rem;
    color: var(--bluon--darker--gray);
    letter-spacing: 0.01rem;
    margin-left: 0.25rem;
  }
}