.bl-unconfirmed-customers-modal {
  .MuiPaper-root {
    padding: 2rem 0;
  }

  .MuiIconButton-root {
    position: absolute;
    top: 0.7rem;
    right: 0.7rem;
  }

  .MuiDialogContent-root {
    gap: 1rem;
    display: flex;
    padding: 1rem 1.5rem;
  }

  .MuiDialogTitle-root {
    font-size: 1.5rem;
    font-weight: 400;
  }
}
