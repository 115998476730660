.checkbox-label {
  &.MuiInputLabel-root {
    font-weight: 500;
    font-size: 0.875rem;
    color: var(--bluon--darker--gray);
    margin: 0;
    padding-top: 0.2em;
  }
  a {
    color: var(--bluon--brand--blue);
    cursor: pointer;
  }
}
