.cm-container-info {
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .cm-advertisement-container {
    margin-bottom: 1.5rem;

    img {
      max-width: 60vw;
      max-height: 12.5rem;
      object-fit: contain;
    }
  }
  .cm-container-info-title,
  .cm-container-info-options,
  .cm-container-info-actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cm-container-info-title {
    flex-direction: column;
    margin-bottom: 1.5rem;

    .claim-job-text,
    .contractor-name-text,
    .tech-name-text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.009rem;
      color: var(--bluon--default--white);
    }

    .claim-job-text {
      font-size: 1rem;
    }

    .contractor-name-text {
      font-size: 1.5rem;
    }

    .tech-name-text {
      font-size: 1.25rem;
    }
  }

  .cm-container-info-options {
    margin-bottom: 1rem;
    flex-wrap: wrap;
    max-width: 62rem;
    .cm-list-container {
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
      overflow: auto;

      &::-webkit-scrollbar-thumb {
        background-color: linear-gradient(0deg, #979797, #979797), #979797;
        -webkit-border-radius: 1vw;
        border-radius: 1vw;
      }

      &::-webkit-scrollbar-track-piece {
        background: linear-gradient(0deg, #eeeeee, #eeeeee), #979797;
        border-left: 1px solid rgba(1, 91, 188, 0.1);
        border-right: 1px solid rgba(1, 91, 188, 0.1);
        border-radius: 1000px;
      }

      &::-webkit-scrollbar {
        width: 10px;
        background: inherit;
      }
    }

    .item-btn {
      background-color: transparent;
      border: 1px solid var(--bluon--default--white);
      width: 100%;
      height: 3.5rem;
      max-width: 11rem;
      border-radius: 0.5rem;
      box-shadow: none;
      margin-right: 1rem;
      margin-bottom: 1rem;

      &.selected {
        background: rgba(41, 134, 243, 0.4);
      }

      &:hover {
        background: rgba(182, 215, 255, 0.1);
      }

      .supplier-btn-text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 150%;
        color: var(--bluon--default--white);
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .cm-container-info-actions {
    flex-direction: column;

    .cm-container-info-actions-got-it {
      margin-bottom: 1.5rem;

      .got-it-btn {
        background-color: var(--bluon--default--blue-4);
        box-shadow: none;
        height: 2.4rem;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 0.938rem;
        line-height: 1.625rem;
        letter-spacing: 0.029rem;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.87);

        &.disabled {
          background: rgba(255, 255, 255, 0.12);
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }

    .cm-container-info-actions-add-new-person {
      margin-bottom: 1rem;
      .add-text-btn {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 0.938rem;
        line-height: 1.625rem;
        letter-spacing: 0.029rem;
        text-transform: uppercase;
        color: var(--bluon--default--white);
        position: relative;

        &.disabled {
          color: var(--bluon--light--gray-23);
        }

        &:hover .custom-tooltip {
          visibility: visible;
        }

        .custom-tooltip {
          visibility: hidden;
          background-color: var(--bluon--light--gray-23);
          text-align: center;
          border-radius: 0.25rem;
          padding: 0.25rem 0.5rem;
          width: auto;
          max-width: 17.188rem;

          /* Position the tooltip */
          position: fixed;
          z-index: 1;
          margin-bottom: 4.688rem;

          /* Font style of tooltip */
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 0.625rem;
          line-height: 0.875rem;
          color: var(--bluon--default--white);
          text-transform: none;

          &::after {
            content: '';
            position: absolute;
            left: 47%;
            top: 100%;
            width: 0;
            height: 0;
            border-left: 0.625rem solid transparent;
            border-right: 0.625rem solid transparent;
            border-top: 0.625rem solid var(--bluon--light--gray-23);
            clear: both;
          }
        }
      }
    }
  }

  .cm-container-add-counter-staff-btn {
    .add-counter-staff-btn {
      height: 2.4rem;
      background-color: var(--bluon--default--blue-4);
      box-shadow: none;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 0.938rem;
      line-height: 1.625rem;
      letter-spacing: 0.029rem;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.87);
    }
  }
}
