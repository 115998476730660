td .bl-delete-modal-button,
td .bl-delete-modal-button:hover {
  border-color: var(--bluon--default--black);
  color: var(--bluon--default--black);
}

.bl-delete-modal-dialog-container {
  .MuiPaper-root {
    width: 400px;
  }

  .bl-delete-modal-avatar-container {
    display: flex;
    justify-content: center;
    margin: 1rem;
  }

  .bl-delete-modal-dialog-text {
    text-align: center;
    color: var(--bluon--default--black);
    margin-bottom: 1.5rem;
  }

  .bl-delete-modal-customer-name {
    font-weight: bold;
  }

  .bl-delete-modal-buttons-container {
    justify-content: center;
    column-gap: 10px;
  }

  .bl-delete-modal-cancel-button-container {
    text-align: right;
  }
}
