.dialog-container {
  .MuiPaper-root {
    max-width: 500px;
  }

  .title-container {
    margin-bottom: 0.875em;
  }

  .gift-box-container {
    margin-top: 1.1875em;
  }

  .MuiDialogContent-root {
    padding: 1.625em;
  }
  .dialog-actions {
    display: flex;
    justify-content: center;
    padding: 0;
    margin-bottom: 1.5em;
    .start-button {
      &.MuiButton-root {
        width: 99px;
        height: 32px;
      }
    }
  }
}
