.container-all {
  position: relative;
  display: flex;
  align-items: center;
}

.container-badge {
  position: absolute;
  right: 0;
  bottom: 0;
  transition: width 0.2s;
  display: flex;
  align-items: center;

  -webkit-box-orient: horizontal;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.container-icon {
  position: absolute;
  z-index: 1;
  height: 2.688rem;
  width: 2.688rem;
  right: 0;
  display: flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--bluon--default--white);
}

.container-text {
  display: flex;
  align-items: center;
  visibility: hidden;
}

.badge-text {
  opacity: 0;

  -moz-transition: opacity 1s;
  -ms-transition: opacity 1s;
  -o-transition: opacity 1s;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

.container-badge:hover .container-text {
  visibility: visible;
}

.container-badge:hover .badge-text {
  opacity: 1;
}
