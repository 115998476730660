.bl-something-wrong-container {
  &.MuiContainer-root {
    align-items: center;
    background-color: var(--bluon--default--white);
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
    max-width: none;
  }

  .bl-something-wrong-info {
    align-items: center;
    display: flex;
    flex-direction: column;

    .bl-something-wrong-icon-error {
      margin-bottom: 0.5em;
      width: 32px;
      height: 32px;
      color: var(--bluon--light--navy-1);
    }

    .bl-something-wrong-icon-reload {
      width: 27px;
      height: 27px;
    }

    .something-wrong-title,
    .something-wrong-subtitle {
      color: var(--bluon--light--navy-1);
      font-size: 0.875rem;
      line-height: 16.41px;
    }

    .something-wrong-title {
      font-weight: 700;
      margin-bottom: 0.5em;
    }

    .something-wrong-subtitle {
      font-weight: 400;
      margin-bottom: 1em;
    }
  }
}
