.staff-wrapper {
  label {
    &.MuiFormLabel-root {
      color: var(--bluon--ds--lightblack);
    }
  }

  .MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-focused {
    fieldset {
      &.MuiOutlinedInput-notchedOutline {
        border-color: var(--bluon--ds--select--border);
      }
    }
  }
}
.add-new-counter-button {
  &.MuiMenuItem-root {
    width: 100%;
    height: 100%;
    display: flex;
    color: var(--bluon--ds--button--highlight--text);
    gap: 0.5rem;
    line-height: 1.5rem;
  }
}
.MuiList-root.MuiMenu-list {
  padding-top: 0;
  padding-bottom: 0;
}
