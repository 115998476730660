.bl-list-item {
  &.MuiListItem-root {
    border-bottom: 1px solid var(--bluon--light--gray-7);
  }
  .bl-branch-logo {
    max-width: 60px;
    margin-right: 1rem;
    object-fit: 'contain';
  }

  .MuiButtonBase-root.MuiListItemButton-root:hover {
    background-color: var(--bluon--ds--lightblue-2);
  }
}
.list-header {
  &.MuiTypography-subtitle2 {
    color: var(--bluon--ds--brand);
    padding: 0.75rem 1rem;
  }
}
