.center-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.bl-dialog {
  .MuiSvgIcon-root {
    padding-top: 10px;
    font-size: 83px;
  }
  .MuiDialogContent-root {
    align-self: center;
    padding-left: 2.2rem;
    padding-right: 2.2rem;
  }
  .MuiDialogActions-root {
    align-self: center;
    padding-bottom: 20px;
  }
}
