.bl-confirmed-account-customer-banner {
  background: var(--bluon--ds--lightblue-2);

  .MuiButtonBase-root {
    padding: 0 0.625rem;
    border: 1px solid var(--bluon--ds--lightgray-3);
    background-color: var(--bluon--ds--white);
  }

  .MuiTypography-subtitle1 {
    font-weight: 700;
    color: var(--bluon--ds--lightblue-3);
  }

  .copied-button {
    color: var(--bluon--ds--black);
    gap: 0.5rem;
    display: flex;
    align-items: center;

    .MuiSvgIcon-root {
      font-size: 1rem;
    }
  }
}
