.orders-logs-container {
  height: 100%;

  .page-footer {
    box-shadow:
      0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 5px 8px rgba(0, 0, 0, 0.14),
      0px 1px 14px rgba(0, 0, 0, 0.12);
  }
}
