.unlisted-part-form {
  .MuiFormHelperText-root {
    color: var(--bluon--ds--black);
    opacity: 60%;

    &.Mui-error {
      color: var(--bluon--ds--lightred);
      opacity: 100%;
    }
  }
}
