.bl-number-format-container {
  position: relative;

  .bl-number-format-error-icon {
    height: 26px;
    width: 26px;
  }
}

.bl-number-format {
  padding: 0.8125rem;
  background: var(--bluon--light--gray-6);
  border: none;
  border-radius: 3px;
  font-size: 1rem;

  &.error {
    border: 1px solid var(--bluon--default--peach);
  }
}

.bl-number-format:focus-visible {
  outline: none;
  background-color: var(--bluon--default--white);
  border: 1px solid var(--bluon--default--blue--gray-1);
}
