.cm-add-staff-container {
  width: 100%;
  height: calc(100% - 3.188rem);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .cm-add-staff-title-section,
  .cm-add-staff-inputs-section,
  .cm-add-staff-actions-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cm-add-staff-title-section {
    margin-bottom: 2rem;

    .title-text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 2.125rem;
      line-height: 2.624rem;
      letter-spacing: 0.016rem;
      color: var(--bluon--default--white);
    }
  }

  .cm-add-staff-inputs-section {
    margin-bottom: 2rem;

    .cm-add-staff-input-name-section,
    .cm-add-staff-other-inputs-section {
      width: 31.25rem;
    }

    .cm-add-staff-input-name-section {
      margin-bottom: 1.5rem;
    }

    .cm-add-staff-other-inputs-section {
      display: flex;
      gap: 1rem;

      .add-new-counter-staff-phone-input {
        width: 50%;
      }
      .add-new-counter-staff-email-input {
        width: 50%;
      }
    }
  }

  .cm-add-staff-actions-section {
    flex-direction: column;

    .cm-add-staff-action-add {
      margin-bottom: 1rem;

      .add-counter-staff {
        background-color: var(--bluon--default--blue-4);
        box-shadow: none;
        height: 2.625rem;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 0.938rem;
        line-height: 1.625rem;
        letter-spacing: 0.029rem;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.87);

        :disabled {
          background: rgba(255, 255, 255, 0.12);
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }

    .cm-add-staff-action-cancel {
      .cancel-text-btn {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 0.938rem;
        line-height: 1.625rem;
        letter-spacing: 0.029rem;
        text-transform: uppercase;
        color: var(--bluon--default--white);
        position: relative;
        height: 1.875rem;
      }
    }
  }
}
