.from-model-button-wrapper {
  button {
    color: var(--bluon--ds--button--lightgray--border);
    border: 1px solid var(--bluon--ds--button--lightgray--border);
    padding: 0.188rem 0.625rem;
    font-size: 0.813rem;
    line-height: 1.125rem;
    font-weight: 400;
    letter-spacing: 0.01rem;

    &:hover {
      color: var(--bluon--ds--button--lightgray--border);
      border: 1px solid var(--bluon--ds--button--lightgray--border);
    }

    .css-ptiqhd-MuiSvgIcon-root {
      height: 0.75rem;
      width: 0.75rem;
      margin-left: 0.75rem;
    }
  }
}