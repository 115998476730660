.bl-card-title {
  &.MuiTypography-h6 {
    font-size: 1.125rem;
  }
}

.bl-card-subtitle {
  &.MuiTypography-subtitle1 {
    font-size: 0.875rem;
  }
}
