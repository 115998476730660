.time-picker-min-width {
  &.MuiOutlinedInput-root {
    cursor: pointer;
    padding: 0 0 0 1rem;
    max-height: 2.75rem;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}
