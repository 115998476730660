.container-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

.counter-staff-item {
  .MuiListItemIcon-root {
    justify-content: flex-end;
  }
}

.dropdown-counter-staff {
  background-color: var(--bluon--ds--selected);

  .MuiMenuItem-root.MuiMenuItem-gutters {
    background-color: var(--bluon--ds--selected);
  }

  .MuiMenuItem-root.Mui-selected {
    background-color: var(--bluon--ds--highlight);
    color: var(--bluon--ds--brand);
  }
  .dropdown-caption-title {
    .MuiTypography-root {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 0.75rem;
      padding: 0.25rem;
    }
  }
}

.bl-menu-list-container {
  .MuiPaper-root {
    display: flex;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

    .MuiList-root {
      width: 100%;
      padding: 0;

      .MuiButtonBase-root {
        width: 100%;
        display: flex;
        padding: 0.75rem 1rem;
        gap: 0.5rem;
      }
    }
  }
}

.MuiPaper-root {
  .MuiList-root {
    .MuiButtonBase-root {
      &:last-of-type {
        padding: 1rem 1rem;

        &:not(:only-of-type) {
          border-top: 1px solid var(--bluon--ds--gray-200);
        }
      }
    }
  }
}
