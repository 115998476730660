:root {
  --bluon--ds--selected: #f8f8f8;
  --bluon--ds--brand: #0567d1;
  --bluon--ds--brand--hover: #0567d11a;
  --bluon--ds--brand--light--background: #1976d214;
  --bluon--ds--red: #de360c;
  --bluon--ds--red--background--light: #d32f2f0a;
  --bluon--ds--white: #fff;
  --bluon--ds--disabled: #a6a6a6;
  --bluon--ds--black: #000000;
  --bluon--ds--highlight: #e6f0fa;
  --bluon--ds--lightgray: #fafafa;
  --bluon--ds--lightgray-1: #626262;
  --bluon--ds--lightgray-2: #8c8c8c;
  --bluon--ds--lightgray-3: #e0e0e0;
  --bluon--ds--lightgray-4: #f0f0f0;
  --bluon--ds--lightgray-5: #f7f7f7;
  --bluon--ds--lightgray-6: #dfdfdf;
  --bluon--ds--lightgray-7: #f8f8f8;
  --bluon--ds--darkblue: #294295;
  --bluon--ds--lightblack: #00000099;
  --bluon--ds--lightblack-primary: #000000de;
  --bluon--ds--lightgray-primary: #00000061;
  --bluon--ds--lightgray-background: #0000001f;
  --bluon--ds--lightgray-button-bg: #e0e0df;
  --bluon--ds--lightgray-button-text: #00000042;
  --bluon--ds--border--lightgray: #e2e2e2;
  --bluon--ds--darkblue-01: #0567d1;
  --bluon--ds--button--primary--border: #1976d280;
  --bluon--ds--lightblue: #1976d2;
  --bluon--ds--lightblue-2: #f1f8ff;
  --bluon--ds--lightblue-3: #2990ff;
  --bluon--ds--gray--bg: #f6f6f6;
  --bluon--ds--gray--3: #bfbfbf;
  --bluon--ds--lightgreen: #4caf50;
  --bluon--ds--lightred: #d32f2f;
  --bluon--ds--lighorange: #ed6c02;
  --bluon--ds--button--lightgray--border: rgba(0, 0, 0, 0.38);
  --bluon--ds--light-black: rgba(0, 0, 0, 0.6);
  --bluon--ds--button--primary--border: #1976d280;
  --bluon--ds--button--highlight--text: #1976d2;
  --bluon--ds--star--yellow: #faaf00;
  --bluon--ds--select--border: rgba(0, 0, 0, 0.23);
  --bluon--ds--curri--message: #007d31;
  --bluon--ds--search-light-black: #0000008a;
  --bluon--ds--label-black: #212121;
  --bluon--ds--dark-grey: #3d5066;
  --bluon--ds--light-blue: #e6eff8;
  --bluon--ds--light-gray-4: #e1e1e1;
  --bluon--ds--light-gray-5: #0000003b;
  --bluon--ds--light-blue-shades: #1976d21f;

  //  General colors
  --bluon--ds-white: #ffffff;
  --bluon--ds-default-bg: #f0f0f0;
  --bluon--ds--black: #000000;
  --bluon--ds--red: #d31a00;
  --bluon--ds--label-black: #323e4a;
  --bluon--ds--label--web--blues: #000719;
  --bluon--ds--black-30: rgba(17, 19, 24, 0.3);
  --bluon--ds--black-40: rgba(17, 19, 24, 0.4);
  --bluon--ds--black-60: rgba(17, 19, 24, 0.6);
  --bluon--ds--black-87: rgba(17, 19, 24, 0.87);
  --bluon--ds--black-90: rgba(17, 19, 24, 0.9);
  --bluon--ds--green-background: #f3faf6;

  // Text
  --bluon--ds--text-primary: rgba(17, 19, 24, 0.9);

  // Scrollbar
  --bluon--ds--scrollbar: #11131866;
  --bluon--ds--trackBar: #f8f8f8;

  //  Blue
  --bluon--ds--blue-50: #e6ecff;
  --bluon--ds--blue-100: #cedafc;
  --bluon--ds--blue-200: #9eb8ff;
  --bluon--ds--blue-300: #7197fe;
  --bluon--ds--blue-400: #4375fe;
  --bluon--ds--blue-500: #1352fe;
  --bluon--ds--blue-600: #013bda;
  --bluon--ds--blue-700: #012ca3;
  --bluon--ds--blue-800: #001d6b;
  --bluon--ds--blue-900: #001d6b;

  //  Navy
  --bluon--ds--navy-50: #e9edf1;
  --bluon--ds--navy-100: #cfd9e2;
  --bluon--ds--navy-200: #9fb3c6;
  --bluon--ds--navy-300: #718ca9;
  --bluon--ds--navy-400: #4d6680;
  --bluon--ds--navy-500: #304050;
  --bluon--ds--navy-600: #263340;
  --bluon--ds--navy-700: #1d2630;
  --bluon--ds--navy-800: #121a20;
  --bluon--ds--navy-900: #0b0d10;

  //  Gray
  --bluon--ds--gray: #979797;
  --bluon--ds--gray-50: #f9f9fb;
  --bluon--ds--gray-80: #a6a6a6;
  --bluon--ds--gray-100: #f3f4f6;
  --bluon--ds--gray-200: #e7e9ed;
  --bluon--ds--gray-300: #dcdee5;
  --bluon--ds--gray-400: #d1d3dd;
  --bluon--ds--gray-500: #c4c8d4;
  --bluon--ds--gray-600: #959cb2;
  --bluon--ds--gray-700: #67718e;
  --bluon--ds--gray-800: #454b5e;
  --bluon--ds--gray-900: #22262f;
  --bluon--ds--gray-950: #707174;

  //  Yellow
  --bluon--ds--yellow-50: #fffaea;
  --bluon--ds--yellow-100: #fff7dc;
  --bluon--ds--yellow-200: #fdf0b8;
  --bluon--ds--yellow-300: #fee795;
  --bluon--ds--yellow-400: #fddf72;
  --bluon--ds--yellow-500: #fed64d;
  --bluon--ds--yellow-600: #fcc80a;
  --bluon--ds--yellow-700: #c49b04;
  --bluon--ds--yellow-800: #836700;
  --bluon--ds--yellow-900: #423401;

  //  Orange
  --bluon--ds--orange-50: #fff7eb;
  --bluon--ds--orange-100: #fef1db;
  --bluon--ds--orange-200: #ffe1b8;
  --bluon--ds--orange-300: #ffd294;
  --bluon--ds--orange-400: #ffc370;
  --bluon--ds--orange-500: #ffb44d;
  --bluon--ds--orange-600: #ff9908;
  --bluon--ds--orange-700: #c77400;
  --bluon--ds--orange-800: #854d00;
  --bluon--ds--orange-900: #422700;

  //  Green
  --bluon--ds--green-50: #dbffea;
  --bluon--ds--green-100: #bdffd7;
  --bluon--ds--green-200: #7affaf;
  --bluon--ds--green-300: #37ff88;
  --bluon--ds--green-400: #02f562;
  --bluon--ds--green-500: #00b246;
  --bluon--ds--green-600: #008f39;
  --bluon--ds--green-700: #016b2c;
  --bluon--ds--green-800: #00471d;
  --bluon--ds--green-900: #00240e;

  //  Tomato
  --bluon-ds--tomato-50: #ffefed;
  --bluon-ds--tomato-100: #ffe1db;
  --bluon-ds--tomato-200: #ffc1b8;
  --bluon-ds--tomato-300: #ffa08f;
  --bluon-ds--tomato-400: #ff816c;
  --bluon-ds--tomato-500: #ff6348;
  --bluon-ds--tomato-600: #ff2a05;
  --bluon-ds--tomato-700: #c21d01;
  --bluon-ds--tomato-800: #851400;
  --bluon-ds--tomato-900: #420a00;

  //  Text colors
  --bluon-ds--text-primary: rgba(17, 19, 24, 0.9);
  --bluon-ds--text-secondary: rgba(17, 19, 24, 0.6);
  --bluon-ds--text-tertiary: rgba(17, 19, 24, 0.4);
  --bluon-ds--text-disabled: rgba(17, 19, 24, 0.3);
  --bluon-ds--navbar-primary: #707174;
  --bluon-ds--text-black: #111318e5;

  // Generic colors
  --bluon-ds--generic-navy-1: #323e4a;
  --bluon-ds--generic-black-1: #11131899;
  --bluon-ds--generic-disabled-test-1: #1113184d;

  // Events
  --bluon-ds--hover-blue-1: #1352fe0d;
}
