.msg-container-img-file {
  margin-bottom: 0.625em;
  cursor: pointer;

  .msg-img-file {
    height: 100%;
    max-height: 224px;
    max-width: 336px;
    width: 100%;
  }
}

.msg-container-link-pdf {
  align-items: center;
  background-color: var(--bluon--default--blue);
  border-radius: 0.313em;
  box-sizing: border-box;
  display: flex;
  height: 40px;
  left: 12px;
  margin-bottom: 0.625em;
  padding: 0.5em 1em 0.5em 1em;
  top: 10px;
  width: 313px;

  .img-file-pdf {
    margin-right: 0.625em;
  }

  .link-pdf {
    -webkit-box-orient: horizontal;
    -webkit-line-clamp: 1;
    color: var(--bluon--default--white);
    display: -webkit-box;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    height: 10px;
    line-height: 0.75em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
