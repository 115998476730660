.bl-store-info-container {
  .MuiPaper-root {
    padding: 1rem;
    margin: 1.5em 0;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
  }
  .bl-confirmation-container {
    direction: row;
    align-items: center;
    justify-content: center;
  }

  .bl-take-rate-container {
    flex: 1 1 0;
  }

  .typography-label-styles {
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    letter-spacing: 0.006rem;
    color: var(--bluon-ds--text-black);
    display: block;
    transform: translate(0px, -1.7rem) scale(1);
    height: 0;
  }

  .store-info-inputs-container {
    display: flex;
    padding-top: 1.7rem;
    gap: 2rem;
  }

  a {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 166%;
    letter-spacing: 0.025rem;
    color: var(--bluon--ds--black-30);
  }
}
