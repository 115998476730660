.bl-brands-modal {
  .MuiDialog-paper {
    width: 40rem;
  }

  .modal-header-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem 0 1rem;

    .title {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .MuiSvgIcon-root {
        font-size: 1rem;
      }
    }

    .MuiSvgIcon-root {
      opacity: 54%;
    }
  }

  .modal-content {
    padding: 0;
    height: 32.25rem;
    overflow: hidden;
  }

  .chips-container {
    max-height: 10.9rem;
    overflow: auto;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
    padding: 0 1rem 1rem 1rem;
  }

  .MuiInputBase-root.MuiInput-root {
    height: 2.5rem;
    background-color: var(--bluon--ds--white);
    border: 1px solid var(--bluon--ds--light-gray-5);
  }

  .disabled {
    pointer-events: none;
    opacity: 60%;
  }
}
