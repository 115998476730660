.bl-curri-informative-pop-up {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  .informative-price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background: var(--bluon--light-blue--background);

    border: 1px solid var(--bluon--default--blue-3);
    border-radius: 1rem;

    .MuiTypography-h6 {
      color: var(--bluon--default--blue-3);
    }
    .MuiTypography-h5 {
      color: var(--bluon--default--blue-3);
    }
  }

  .informative-stepper {
    width: 100%;
    padding-top: 1rem;

    .MuiTypography-h5 {
      margin-bottom: 1rem;
    }

    .MuiStepLabel-iconContainer {
      .MuiSvgIcon-root {
        color: var(--bluon--default--blue-3);
      }
    }
    .MuiStepLabel-label {
      color: var(--bluon--ds--lightblack-primary);
      font-weight: 500;
      font-size: 0.875rem;
    }
  }

  .MuiDialogActions-root {
    padding: 1rem;
    justify-content: center;

    .MuiButton-root {
      padding: 1rem;
    }
  }
}
